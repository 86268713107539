<template>
	<div class="acc_navbar">
		<div class="aside-bar">
			<el-menu :default-active="activeIndex" ref="test" :unique-opened='true' :default-openeds="defaultOpeneds"
				class="aside-menu-nav" :router="true" @select="handleSelect">
				<el-menu-item index="1" :route="{ path: '/admin' }">
					<img src="../assets/photo/aside3.png" alt="" class="ico"><span>{{$t('nav1')}}</span>
				</el-menu-item>
				<!-- <el-menu-item index="2" :route="{ path: '/collection' }">
					<img src="../assets/photo/aside1.png" alt="" class="ico"><span>{{$t('meun13_2')}}</span>
				</el-menu-item>
				<el-submenu index="3">
					<template slot="title">
						<img src="../assets/photo/aside2.png" alt="" class="ico"><span>{{$t('meun13_3')}}</span>
					</template>
					<el-menu-item index="3-1" :route="{ path: '/promotion' }">{{$t('nav2')}}</el-menu-item>
					<el-menu-item index="3-2" :route="{ path: '/myCards' }">{{$t('nav3')}}</el-menu-item>
				</el-submenu> -->
				<el-menu-item index="4" :route="{ path: '/api' }">
					<img src="../assets/photo/aside4.png" alt="" class="ico"><span>{{$t('meun13_4')}}</span>
				</el-menu-item>
			</el-menu>
		</div>
		<i class="iconfont icon-menu1" @click="drawer = true" type="primary"></i>
		<el-drawer :visible.sync="drawer" :direction="direction" size="240px" :router="true">
			<div class="acc_bar">
				<el-menu :default-active="$route.path" class="aside-menu-nav">
					<el-menu-item index="/admin">
						<img src="../assets/photo/aside3.png" alt="" class="ico"><span>{{$t('nav1')}}</span>
					</el-menu-item>
					<el-menu-item index="/collection">
						<img src="../assets/photo/aside1.png" alt="" class="ico"><span>{{$t('meun13_2')}}</span>
					</el-menu-item>
					<el-submenu index="3">
						<template slot="title">
							<img src="../assets/photo/aside2.png" alt="" class="ico"><span>{{$t('meun13_3')}}</span>
						</template>
						<el-menu-item index="/promotion">{{$t('nav3')}}</el-menu-item>
						<el-menu-item index="/myCards">{{$t('nav3')}}</el-menu-item>
					</el-submenu>
					<el-menu-item index="/api">
						<img src="../assets/photo/aside4.png" alt="" class="ico"><span>{{$t('meun13_4')}}</span>
					</el-menu-item>
				</el-menu>
			</div>
		</el-drawer>
	</div>

</template>

<script>
	export default {
		name: 'UserNavA',
		data() {
			return {
				drawer: false,
				direction: 'ltr',
				activeIndex: '1',
				isLogin: false,
				defaultOpeneds: [],
				routeArr: {
					'/admin': '1',
					'/collection': '2',
					'/promotion': '3-1',
					'/myCards': '3-2',
					'/api': '4',
				}
			};
		},
		created() {
			this.init()
			const path = this.$route.path
			this.heightLightMenu(path)
		},
		methods: {
			init() {
				let isLogin = this.$store.state.isLogin
				if (isLogin == false) {
					this.$router.push('/')
				}
			},
			handleSelect(key, keyPath) {
				this.defaultOpeneds = [keyPath[0]]
				this.activeIndex = key
				this.link(this.activeIndex)
			},
			link(code) {
				switch (code) {
					default:
						this.$router.push('/admin')
						break
					case '3-1':
						this.$router.push('/promotion')
						break
					case '3-2':
						this.$router.push('/myCards')
						break
				}
			},
			heightLightMenu(path) {
				let acName = this.routeArr[path]
				let opName = acName.split('-')[0]
				if (opName.length > 0) {
					this.defaultOpeneds = [opName]
				}
				this.activeIndex = acName
				// this.$nextTick(function() {
				// 	this.$refs.test.open()
				// 	this.$refs.test.updateActiveName()
				// })
			}
		},
		watch: {
			$route(to) {
				this.heightLightMenu(to.path)
			}
		}
	}
</script>
<style>
</style>
