<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside">
				<UserNavA></UserNavA>
			</div>
			<div class="admin-col flex_bd whole_000">
				<div class="coll-box">
					<div class="coll-hd flex flex-ac flex-zBetween">
						<div class="coll-tt">
							<div class="coll-name">{{$t('apiTxt')}}</div>
							<div class="coll-tips">{{$t('clTxt')}}</div>
						</div>
						<el-button type="primary" class="btn" @click="add"><i
								class="iconfont icon-plus"></i>{{$t('clTxt2')}}</el-button>
					</div>
					<div class="coll-grid">
						<div class="coll-flex whole_fff" v-for="(item,index) in collLlst" :key="index">
							<div class="coll-f-h flex flex-ac flex-zBetween">
								<div class="coll-fl-t whole-zi">{{item.typeName}}</div>
								<div class="coll-fl-r flex flex-ac">
									<p @click="editClick(item)">{{$t('edit')}}</p>
									<p @click="delClick(item.id)">{{$t('delete')}}</p>
								</div>
							</div>
							<div class="coll-fl-bd">
								<el-row>
									<el-col :lg="8" :md="8" :sm="12" :xs="12">
										<div class="coll-fl-cel">
											<div class="coll-fl-p">{{$t('name')}}</div>
											<div class="coll-fl-span">{{item.field_1}}</div>
										</div>
									</el-col>
									<el-col :lg="8" :md="8" :sm="12" :xs="12">
										<div class="coll-fl-cel">
											<div class="coll-fl-p">{{$t('clTxt3')}}</div>
										<!-- 	<div class="coll-fl-p">{{item.fieldName.field_2}}</div> -->
											<div class="coll-fl-span">{{item.field_2}}</div>
										</div>
									</el-col>
									<el-col :lg="8" :md="8" :sm="12" :xs="12">
										<div class="coll-fl-cel">
											<div class="coll-fl-p">{{$t('clTxt4')}}</div>
											<!-- <div class="coll-fl-p">{{item.fieldName.field_3}}</div> -->
											<div class="coll-fl-span">{{item.field_3}}</div>
										</div>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="coll-modal">
			<el-dialog :visible.sync="dialogVisible" width="666px" top="0">
				<div class="coll-m-top flex flex-ac flex-zBetween">
					<div class="coll-m-tt">{{$t('clTxt5')}}</div>
					<el-input v-model="input" :placeholder="$t('btn6')" prefix-icon="el-icon-search"></el-input>
				</div>
				<div class="coll-m-bd">
					<el-row>
						<el-col :lg="12" :xs="24">
							<div class="coll-m-cel flex flex-ac">
								<p class="coll_yellow"></p>Absolut Bank
							</div>
						</el-col>
					</el-row>
				</div>
			</el-dialog>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		getRecords,
		delPayment
	} from '@/api/api/user'
	// @ is an alias to /src
	import UserNavA from '@/components/UserNavA.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			UserNavA,
			Foot
		},
		data() {
			return {
				collLlst: [],
				dialogVisible: false,
				input: ''
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				getRecords().then(res => {
					if (res.code == 0) {
						this.collLlst = res.data
						console.log(res.data)
					} else {
						this.$notify.error({
							title: this.$t("czTxt9"),
							message: res.message
						});
						this.$router.push("/admin");
					}
				})
			},
			editClick(item) {
				this.$store.commit('SET_PAYINFO', item)
				this.$router.push({
					path: '/collection3'
				})
			},
			add() {
				this.$router.push({
					path: '/collection2'
				})
			},
			delClick(id) {
				delPayment({
					id: id
				}).then(res => {
					console.log(res)
					if (res.code == 0) {
						this.getList()
					} else {
						this.$message.error(res.message);
					}
				})
			}
		}
	}
</script>
